import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateField } from '../../../redux/account/action';
import { nextStep } from '../../../redux/ui/action';
import { Typography, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import ButtonAction from '../../../components/button/button';
const Step3 = () => {
    const invoice = useSelector(state => state.account.invoice);
    const [fattura,setFattura] =useState(invoice);
    const dispatch = useDispatch();


    const handleChange = (e) => {
        let value = e.target.checked;
        let name = e.target.name;
        setFattura(value);
        dispatch(updateField(value, name, "account"))
    }
    return(
        <Grid
        container
        justify="center"
        alignItems="center"
    >
         <Grid item xs={12}>
            <Typography
                align="center"
                paragraph={true}
                variant="h6"
            >
                STAI PER EFFETTUARE L’ACQUISTO COME AZIENDA* O COME PERSONA FISICA?
            </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
            <ButtonAction 
                label="Come persona fisica" 
                type="isCompany"
                val={false}
                group="account"
            />
        </Grid>
        <Grid item xs={12}sm={6} >
            <ButtonAction 
                label="Come azienda" 
                type="isCompany"
                val={true}
                group="account"
            />
        </Grid>
        {/* <Grid item xs={12}>
            <FormControlLabel
                control={<Checkbox checked={fattura} onChange={handleChange} name="invoice" />}
                label="Voglio ricevere la fattura"
            />
        </Grid> */}
        <Grid item xs={12}>
            <Typography
                align="center"
                paragraph={true}
                variant="caption"
            >
                *In caso di acquisto da effettuare come azienda ti verrà chiesto l’inserimento anche delle seguenti info:<br />
Partita IVA, codice SDI e PEC.
            </Typography>
        </Grid>
    </Grid>
    )
}

export default Step3;