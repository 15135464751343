import React from 'react'
import { Typography, Grid } from '@material-ui/core';
import ButtonAction from '../../../components/button/button';
const Step2 = () => {
    return(
    <Grid
        container
        justify="center"
        alignItems="center"
    >
         <Grid item xs={12}>
            <Typography
                align="center"
                paragraph={true}
                variant="h6"
            >
                LA GIFT CARD E' PER TE O E' UN REGALO?
            </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
            <ButtonAction 
                label="É per me" 
                type="isGift"
                val={false}
                group="account"
            />
        </Grid>
        <Grid item xs={12}sm={6} >
            <ButtonAction 
                label="É un regalo" 
                type="isGift"
                val={true}
                group="account"
            />
        </Grid>
    </Grid>
    )
}

export default Step2;