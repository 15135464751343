import { 
    PAYMENT_GIFTCARD,
    PAYMENT_ONE_CLICK
} from '../../redux/actions';

export const paymentsGiftCard = () => {
    return {
        type: PAYMENT_GIFTCARD
    }
}
export const paymentOneClick = () => {
    return {
        type: PAYMENT_ONE_CLICK
    }
}