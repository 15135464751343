import React, {useEffect} from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { Typography, Grid} from '@material-ui/core';
import ButtonAction from '../../../components/button/button';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { nextStep, loading } from '../../../redux/ui/action';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles({
    root: {
      backgroundColor: '#d4972b',
      borderRadius: 0,
      color: 'white',
      width: '100%',
      maxWidth: '280px',
      padding: '16px 4px',
      textTransform: 'capitalize',
      fontStyle: 'italic',
      fontWeight: 'bold',
      margin: '1rem auto',
      display: 'block',
      '&:hover': {
          backgroundColor: '#d4972ba6'
      }
    },
  });

const Step5 = () => {
    const classes = useStyles();

    const state = useSelector(state => state);
    const dispatch = useDispatch()
    const statusOperation = useSelector(state => state.ui.statusOperation);
    const loadingCircular = useSelector(state => state.ui.loading);

    const onSubmitHandler = () => {
        dispatch({type: "VERIFY_PAYMENT_ONE_CLICK"});
        dispatch(loading(true))
    }

    useEffect(() => {
        if(statusOperation === true ) {
            dispatch(loading(false))
            dispatch(nextStep(7));
        }         
    }, [statusOperation]);

    return(
        <Grid
        container
        justify="center"
        alignItems="center"
    >
         <Grid item xs={12}>
            <Typography
                align="center"
                
                variant="h6"
            >
               RIEPILOGO DATI
            </Typography>
        </Grid>

        <Grid item xs={12}>
            <Grid
                container
                justify="center"
                alignItems="center"
            >
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >IMPORTO GIFT CARD</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.amount},00 €
                    </Typography>
                </Grid>  
                {state.account.isCompany === "false" ? <>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >NOME</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.name}
                    </Typography>
                </Grid>     
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >COGNOME</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.surname}
                    </Typography>
                </Grid>   
                {state.account.invoice === true && <>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >CODICE FISCALE</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.fiscalCode}
                    </Typography>
                </Grid>   
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >DATA DI NASCITA</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.dateBirth}
                    </Typography>
                </Grid>     
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >SESSO</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.gender === 1 ? "Maschio" : "Femmina"}
                    </Typography>
                </Grid> 
                </>} 
                </> : <>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >RAGIONE SOCIALE</Typography>
                </Grid>                
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.surname}
                    </Typography>
                </Grid>    
                {state.account.invoice === true && <> 
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >PARTITA IVA</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.vat}
                    </Typography>
                </Grid> 
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >CODICE SDI</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.sdi}
                    </Typography>
                </Grid>     
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >PEC</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.pec}
                    </Typography>
                </Grid> 
                </>}   
                </>}    
                {state.account.invoice === true && <>             
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >INDIRIZZO</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.address}
                    </Typography>
                </Grid>     
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >LOCALITA'</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.city}
                    </Typography>
                </Grid>     
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >PROVINCIA</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.province}
                    </Typography>
                </Grid>     
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >CAP</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.zip}
                    </Typography>
                </Grid>  
                </>}  
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >NUMERO DI TELEFONO</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.phone}
                    </Typography>
                </Grid>  
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >INDIRIZZO E MAIL</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.email}
                    </Typography>
                </Grid> 
                {state.account.isGift === "true" && <> 
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >NOME DEL DESTINATARIO</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.nameRecipient}
                    </Typography>
                </Grid>  
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >COGNOME DEL DESTINATARIO</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.surnameRecipient}
                    </Typography>
                </Grid>  
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >NUMERO DI TELEFONO DEL DESTINATARIO</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.phoneRecipient}
                    </Typography>
                </Grid>  
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >INDIRIZZO E MAIL DEL DESTINATARIO</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.emailRecipient}
                    </Typography>
                </Grid>     
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >IL TUO MESSAGGIO</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.message}
                    </Typography>
                </Grid> 
                </>}      
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body1"
                    >PRIVACY</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        align="left"
                        
                        variant="body2"
                    >
                        {state.account.privacy === true ? "Accettata" : "Non Accettata"}
                    </Typography>
                </Grid>       
            </Grid>
        </Grid>

        <Grid item xs={12}>

            {loadingCircular && <CircularProgress style={{margin: 'auto', display: 'block'}} color="secondary" />}

            {state.ui.bodyNexi.alias ? (<form method='POST' onSubmit={onSubmitHandler} action='https://ecommerce.nexi.it/ecomm/ecomm/DispatcherServlet' target="_blank">
                <input type="hidden" name="alias" value={`${String(state.ui.bodyNexi.alias).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')}`} />
                <input type="hidden" name="codTrans" value={`${String(state.ui.bodyNexi.codTrans).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')}`} />
                <input type="hidden" name="divisa" value={`${String(state.ui.bodyNexi.divisa).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')}`}/>
                <input type="hidden" name="importo" value={`${String(state.ui.bodyNexi.importo).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')}`} />
                <input type="hidden" name="mac" value={`${String(state.ui.bodyNexi.mac).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')}`} />
                <input type="hidden" name="url" value={`${String(state.ui.bodyNexi.url).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')}`} />
                <input type="hidden" name="url_back" value={`${String(state.ui.bodyNexi.url_back).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')}`} />
                <input type='submit' className={classes.root} value='Acquista' />
            </form>) : <Skeleton variant="rect" width={280} height={51} />

            }



           

           {/*  <ButtonAction 
                label="Acquista" 
            /> */}
        </Grid>
    </Grid>
    )
}

export default Step5;