import { 
    UPDATE_FIELD
} from '../../redux/actions';

export const updateField = (value, name, group) => {
    return {
        type: UPDATE_FIELD,
        payload: value,
        name,
        group
    }
};