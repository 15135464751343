import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateField } from '../../redux/account/action';
import { MenuItem, FormControl, InputLabel, Select } from '@material-ui/core';
import css from './field.module.sass';

const SelectProvinces = () => {
    const [value,setValue] = useState("");
    const dispatch = useDispatch();
    const provincesArray = useSelector(state=>state.ui.provinces)
    const handleChange = (e) => {
        let { value, name } = e.target;
        setValue(value);
        dispatch(updateField(value, name, "account"))
    }
    return(
        <FormControl variant="outlined" className={css.FormControl}>
            <InputLabel id="province" >Provincia</InputLabel>
            <Select
                labelId="province"
                value={value}
                onChange={handleChange}
                label="Provincia"
                required
                name="province"
            >
                {provincesArray.map(provincia => <MenuItem key={provincia.Code} value={provincia.Code}>{provincia.Description}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

export default SelectProvinces;