import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getProvinces }from '../redux/ui/action'
import app from './app.module.sass'
import GridContainer from '../layout/GridContainer';
import Step1 from './pages/step1/gift-value'; //value gift
import Step2 from './pages/step2/isAGift'; //is a Gift?
import Step3 from './pages/step3/areYouBusiness'; //areYouBusiness?
import Step4 from './pages/step4/dataEntry'; //dataEntry
import Step5 from './pages/step5/summary'; //summary
import Step6 from './pages/step6/payment'; //payment
import Step7 from './pages/step7/thankyou'; //payment

function App() {
  const dispatch = useDispatch()
  const step = useSelector(state => state.ui.step)
  const provinces = useSelector(state => state.ui.provinces)
  useEffect(() => {
    dispatch(getProvinces())
  }, []);
  const stepView = () => {
    switch(step){
      case 1:
        return <Step1 />
      case 2:
        return <Step2 />
      case 3:
        return <Step3 />
      case 4:
        return <Step4 />
      case 5:
        return <Step5 />
      case 6:
        return <Step6 />
      case 7:
        return <Step7 />
      default:
        return <Step1 />
    }
  }
  return (
    <div className={app.container}>
      <GridContainer>
        {stepView()}
      </GridContainer>
    </div>
  );
}

export default App;
