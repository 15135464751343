import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { updateField } from '../../redux/account/action';
import { disabledButton, displayError } from '../../redux/errors/actions';
import { paymentsGiftCard, paymentOneClick } from '../../redux/creditCard/action'
import { nextStep, loading } from '../../redux/ui/action';
import {Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
      backgroundColor: '#d4972b',
      borderRadius: 0,
      color: 'white',
      width: '100%',
      maxWidth: '280px',
      padding: '16px 4px',
      textTransform: 'capitalize',
      fontStyle: 'italic',
      fontWeight: 'bold',
      margin: '1rem auto',
      display: 'block',
      '&:hover': {
          backgroundColor: '#d4972ba6'
      }
    },
  });

const ButtonAction = ({label, val, type, group}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const step = useSelector(state => state.ui.step);
    const statusOperation = useSelector(state => state.ui.statusOperation);
    const buttonDisable = useSelector(state => state.errors.buttonDisable);
    const amount = useSelector(state => state.account.amount);
    const valid = useSelector(state => state.errors.valid);    


    useEffect(() => {
        if(step === 1 && amount ) {
            dispatch(disabledButton(false));
        }           
    }, [amount]);

    useEffect(() => {
        if(process.env.REACT_APP_STAGE === "qa" ) return dispatch(disabledButton(false));         

        if(step === 4 && valid.account ) {
            dispatch(disabledButton(false));
        } else if(step === 6 && valid.creditCard ) {
            dispatch(disabledButton(false));
        } else { 
            dispatch(disabledButton(true));  
        }  
    }, [valid]);

    useEffect(() => {
        if(step === 2 || step === 3 || step === 5 ) {
            dispatch(disabledButton(false));
        } else {
            dispatch(disabledButton(true));
        }           
    }, []);

    
    useEffect(() => {
        if(step === 5 && statusOperation === true ) {
            dispatch(loading(false))
            dispatch(nextStep(7));
        } else {
            dispatch(loading(false))
        }           
    }, [statusOperation]);

    const handleClick = (val, type, group) =>{
        if(val && type) dispatch(updateField(val, type, group))
        if(step === 4) {
            dispatch(paymentsGiftCard())
            dispatch(nextStep())
        } else if(step === 5){
            dispatch(displayError(false));
            dispatch(loading(true))
            dispatch(paymentOneClick())
        } else {
            dispatch(nextStep())
        }       
    }


    return(
        <Button 
            className={classes.root}
            onClick={() => handleClick(val, type, group)}
            disabled={buttonDisable}
        >
            {label}
        </Button>
    )
}

export default ButtonAction;