import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import { updateField } from '../../redux/account/action';
import {Typography} from '@material-ui/core';

import { KeyboardDatePicker, MuiPickersUtilsProvider  } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

const Data = () => {
    const [error, setError] = useState(false);
    const dispatch = useDispatch();

    const [selectedDate, setDate] = useState(moment());
    const [inputValue, setInputValue] = useState(moment().format("DD/MM/YYYY"));
  
    const onDateChange = (date, value) => {
      setDate(date);
      setInputValue(value);
      if(value) {
        setError(false);
        dispatch(updateField(moment(value, "DD/MM/YYYY").format("YYYY-MM-DD"), "dateBirth", "account"))          
    } else {
        setError(true)
    }
    };
  
    const dateFormatter = str => {
      return str;
    };

    return(
        <>
        <Typography variant="body1" align="center" paragraph={true}>Data di nascita</Typography>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
                autoOk={true}
                showTodayButton={false}
                value={selectedDate}
                format="DD/MM/YYYY"
                inputValue={inputValue}
                onChange={onDateChange}
                rifmFormatter={dateFormatter}
                variant="outlined"
                minDate={moment().subtract(100, "years")}
                maxDate={moment().subtract(18, "years")}
                maxDateMessage="Devi avere almeno 18 anni"
            /> 
        </MuiPickersUtilsProvider>   
        </> 
    )
}

export default Data;