import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateField } from '../../../redux/account/action';
import { errorAction } from '../../../redux/errors/actions';

import { Typography, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import ButtonAction from '../../../components/button/button';
import TextInput from '../../../components/Fields/inputText';
import SelectGender from '../../../components/Fields/selectGender';
import SelectProvinces from '../../../components/Fields/selectProvinces';
import Data from '../../../components/Fields/dateField';
const Step4 = () => {
    const dispatch = useDispatch();
    const account = useSelector(state => state.account);
    const errors = useSelector(state => state.errors);

    const [privacy,setPrivacy] =useState(account.privacy);

    const handleChangeCheckbox = (e) => {
        let value = e.target.checked;
        let name = e.target.name;
        setPrivacy(value);
        dispatch(updateField(value, name, 'account'));
    }

    useEffect(() => {
        dispatch(errorAction('account'));
    },[account.privacy])

    const isGiftFields = () => {
        return (
            <>
        <Grid item xs={12}>
            <Typography
                align="center"
                paragraph={true}
                variant="h6"
            >
                INSERISCI I DATI DEL DESTINATARIO DELLA CARD
            </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextInput
                label="Nome Destinatario"
                name="nameRecipient"
                group="account"
                error={errors.nameRecipient}                                 
            />
        </Grid>   
        <Grid item xs={12} sm={4}>
            <TextInput
                label="Cognome Destinatario"
                name="surnameRecipient"
                group="account"
                error={errors.surnameRecipient}                                 
            />
        </Grid>
        <Grid item xs={12} sm={2}>
        </Grid> 
        <Grid item xs={12} sm={2}>
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextInput
                label="Numero di telefono"
                name="phoneRecipient"
                group="account"
                type="tel"
                InputProps="+39"
                error={errors.phoneRecipient}                                 
            />
        </Grid>   
        <Grid item xs={12} sm={4}>
            <TextInput
                label="E Mail"
                name="emailRecipient"
                group="account"
                type="email"
                error={errors.emailRecipient}                                 
            />
        </Grid>
        <Grid item xs={12} sm={2}>
        </Grid> 
        <Grid item xs={12} sm={2}>
        </Grid>
        <Grid item xs={12} sm={8}>
            <TextInput
                label="Il tuo messaggio"
                name="message"
                multiline
                rows="2"
                group="account"
                error={errors.message}                                 
            />
        </Grid>  
        <Grid item xs={12} sm={2}>
        </Grid> 
        </>
        )
    }
    const renderIfinvoice = () => {
        if(account.invoice === true){
            return(
                <>
                {account.isCompany === false ? 
                (<>
                <Grid item xs={12} sm={4}>
                    <TextInput
                        label="Nome"
                        name="name"   
                        group="account"   
                        error={errors.name}                                 
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput
                        label="Cognome"
                        name="surname"
                        group="account"
                        error={errors.surname}                       
                    />
                </Grid>
                </>)
                : (            
                <>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            label="Ragione Sociale"
                            name="surname"
                            group="account"
                            error={errors.surname}                       
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            label="Partita IVA"
                            name="vat"
                            group="account"
                            error={errors.vat}                       
                        />
                    </Grid>        
                </>)}
                <Grid item xs={12} sm={4}>
                    <TextInput
                        label="Codice Fiscale"
                        name="fiscalCode"
                        group="account"
                        error={errors.fiscalCode}                       
                    />
                </Grid>  
                {account.isCompany === false ? 
                (<>      
                <Grid item xs={12} sm={4}>
                    <Data />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <SelectGender />
                </Grid>
                </>) : (
                    <>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            label="Codice SDI"
                            name="sdi"
                            group="account"
                            error={errors.sdi}                       
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            label="PEC"
                            name="pec"
                            group="account"
                            type="email"
                            error={errors.pec}                       
                        />
                    </Grid>
                    </>
                )}
                <Grid item xs={12} sm={4}>
                    <TextInput
                        label="Indirizzo"
                        name="address"
                        group="account"
                        error={errors.address}                       
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput
                        label="Città di residenza"
                        name="city"
                        group="account" 
                        error={errors.city}                       
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <SelectProvinces />                   
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput
                        label="CAP"
                        name="zip"
                        group="account"
                        type="tel"
                        error={errors.zip}
                    />
                </Grid>   
                <Grid item xs={12} sm={2}>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput
                        label="Numero di telefono"
                        name="phone"
                        group="account"
                        type="tel"
                        InputProps="+39"
                        error={errors.phone}
                    />
                </Grid>   
                <Grid item xs={12} sm={4}>
                    <TextInput
                        label="E Mail"
                        name="email"
                        group="account"
                        type="email"
                        error={errors.email}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                </Grid> 
                </>
            )
        } else {
            return(
                <>
                {account.isCompany === false ? 
                <>
                <Grid item xs={12} sm={6}>
                    <TextInput
                        label="Nome"
                        name="name"      
                        group="account"   
                        error={errors.name}       
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput
                        label="Cognome"
                        name="surname"
                        group="account"
                        error={errors.surname}
                    />
                </Grid>
                </>
                :            
                    <Grid item xs={12} sm={12}>
                        <TextInput
                            label="Ragione Sociale"
                            name="surname"
                            group="account"
                            error={errors.surname}
                        />
                    </Grid>      
                }
             
                <Grid item xs={12} sm={6}>
                    <TextInput
                        label="Numero di telefono"
                        name="phone"
                        group="account"
                        type="tel"
                        InputProps="+39"
                        error={errors.phone}
                    />
                </Grid>   
                <Grid item xs={12} sm={6}>
                    <TextInput
                        label="E Mail"
                        name="email"
                        group="account"
                        type="email"
                        error={errors.email}
                    />
                </Grid>
                </>
            )
        }
    }
    
    return(
        <Grid
        container
        justify="center"
        alignItems="flex-end"
        >
         <Grid item xs={12}>
            <Typography
                align="center"
                paragraph={true}
                variant="h6"
            >
                INSERISCI I TUOI DATI
            </Typography>
        </Grid>

        {renderIfinvoice()}

        {account.isGift === true && (
            isGiftFields()
        )}
        
        <Grid item xs={12}>
            <FormControlLabel
                control={<Checkbox checked={privacy} onChange={handleChangeCheckbox} name="privacy" />}
                label={
                    <Typography>
                        Ho letto ed accetto le <a href="https://qvintoroma.it/regolamento-gift-card/">condizioni e i termini di utilizzo</a>
                    </Typography>
                }
            />
        </Grid>
        <Grid item xs={12}>
            <ButtonAction 
                label="Procedi" 
            />
        </Grid>
    </Grid>
    )
}

export default Step4;