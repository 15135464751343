import React, {useState} from 'react'
import { useDispatch } from 'react-redux';
import { updateField } from '../../redux/account/action';
import { MenuItem, FormControl, InputLabel, Select, Typography } from '@material-ui/core';
import css from './field.module.sass';

const SelectGender = () => {
    const [value,setValue] = useState(1);
    const dispatch = useDispatch();
    const handleChange = (e) => {
        let { value, name } = e.target;
        setValue(value);
        dispatch(updateField(value, name, "account"))
    }
    return(
        <>
        <Typography variant="body1" align="center" paragraph={true}>Sesso</Typography>
        <FormControl variant="outlined" className={css.FormControl}>
            <InputLabel id="gift-value" >Sesso</InputLabel>
            <Select
                labelId="gift-value"
                name="gender"
                value={value}
                onChange={handleChange}
                label="Sesso"
                required
            >
                <MenuItem value={1}>Maschio</MenuItem>
                <MenuItem value={2}>Femmina</MenuItem>
            </Select>
        </FormControl>
        </>
    )
}

export default SelectGender;