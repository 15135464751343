import React,{ useState, useEffect} from 'react'
import { Typography, Grid} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const Step7 = () => {
    const [success,setSuccess] = useState(1);
    const [msg, setMsg] = useState({title: 'IL TUO ACQUISTO è STATO EFFETTUATO CON SUCCESSO.', ph: 'Riceverai una mail con i dati della tua gift card all’indirizzo e mail da te indicato.'});

    useEffect(() => {
        switch(success){
            case 0:
                return setMsg({title:'',ph:''});
            case 1:
                return setMsg({title: 'IL TUO ACQUISTO è STATO EFFETTUATO CON SUCCESSO.', ph: 'Riceverai una mail con i dati della tua gift card all’indirizzo e mail da te indicato.'});
            default:
                return setMsg({title:'',ph:''});
        }        
    }, [success])


    return(
        <Grid
        container
        justify="center"
        alignItems="center"
    >
         <Grid item xs={12}>
            <Typography
                align="center"                
                variant="h6"
            >
            {success === 0 ? <Skeleton /> : msg.title}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography
                align="center"                        
                variant="body1"
            >
                {success === 0 ? <Skeleton /> : msg.ph}
            </Typography>
        </Grid>
    </Grid>
    )
}

export default Step7;