import React from 'react'
import { Typography, Grid } from '@material-ui/core';
import SelectValue from '../../../components/Fields/selectValue';
import ButtonAction from '../../../components/button/button';

const Step1 = () => {
    return(
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
        >
            <Typography
                align="center"
                paragraph={true}
                variant="h6"
            >
                SELEZIONA L’IMPORTO DELLA TUA GIFT CARD
            </Typography>
            <SelectValue />
            <ButtonAction 
                label="Procedi" 
            />
        </Grid>
    )
}

export default Step1;