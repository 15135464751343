import React from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { backStep } from '../../redux/ui/action';

import {Button} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
      borderRadius: 0,
      color: '#d4972b',
      width: '100%',
      maxWidth: '280px',
      padding: '4px',
      textTransform: 'capitalize',
      fontStyle: 'italic',
      fontWeight: 'bold',
      margin: '1rem auto',
      display: 'block',
      textDecoration: 'underline',
      display: 'flex',
      '&:hover': {
          backgroundColor: '#d4972ba6'
      }
    },
  });

const Back = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const step = useSelector(state => state.ui.step);


    const handleClick = () =>{
        /* if(step === 4) {
            dispatch(backStep(2))
        } else { */
            dispatch(backStep())
        //}
    }

    return(
        <Button 
            className={classes.root}
            startIcon={<ArrowBackIosIcon />}
            onClick={handleClick}
        >
            TORNA INDIETRO
        </Button>
    )
}

export default Back;