import React from 'react'
import {Grid, Container} from '@material-ui/core';
import {useSelector} from 'react-redux';
import css from './container.module.sass';
import Back  from '../../components/button/back';

const GridContainer = ({children}) => {
    const step = useSelector(state => state.ui.step)
    return(
        <Container>
            <Grid container spacing={1} >
                <Grid container item xs={12} spacing={3} classes={css.grid}>
                    {children}
                    {step !== 7 && step !== 1 && <Back />}
                </Grid>
            </Grid>
        </Container>
    )
}

export default GridContainer;