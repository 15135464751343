import React, { Component } from 'react'
import Cards from 'react-credit-cards';
import NumberFormat from 'react-number-format';
import 'react-credit-cards/es/styles-compiled.css';
import { connect } from "react-redux";
import {updateField} from "../../../redux/account/action";
import {errorAction} from "../../../redux/errors/actions";
import { Grid, TextField } from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";
import ButtonAction from '../../../components/button/button';

export class Step6 extends Component {

        state = {
            cvc: '',
            expiry: '',
            focus: '',
            name: '',
            number: '',
          };

        handleInputFocus = (e) => {
            let focusField;
            switch(e.target.name) {
                case "CardholderName":
                    focusField = "name";
                    break;
                case "CardNumber":
                    focusField = "number";
                    break;
                case "ExpirationDate":
                    focusField = "expiry";
                    break;
                case "SecurityCode":
                    focusField = "cvc";
                    break;
                default:
                    focusField = e.target.name;
                    break;
            }
            this.setState({ focus: focusField });
        }

        handleBlur = () => {
            this.props.errorAction("creditCard");
        }    

        handleInputChange = (e) => {
            const { name, value } = e.target;

            const {
                updateField
            } = this.props;

            switch (name) {
                case "CardNumber":
                    updateField(value, name, 'creditCard')
                    break;
                case "CardholderName":
                    updateField(value, name, 'creditCard')
                    break;
                case "ExpirationDate":
                    let expiryDateFormat;
                    let len = value.length;
                    if (len === 2) {
                        expiryDateFormat = value.slice(0, 2) + "/" + value.slice(2, len);
                        updateField(expiryDateFormat, name, 'creditCard')
                    } else {
                        updateField(value, name, 'creditCard')
                    }
                    break;
                case "SecurityCode":
                    updateField(value, name, 'creditCard')
                    break;
                default:
                    updateField(value, name, 'creditCard')
                }
        }

        NumberFormatCustom = (props) => {
            const { inputRef, onChange, ...other } = props;

            return (
                <NumberFormat
                    {...other}
                    getInputRef={inputRef}
                    onValueChange={(values) => {
                        onChange({
                            target: {
                                name: props.name,
                                value: values.value,
                            },
                        });
                    }}                    
                    isNumericString
                />
            );
        }

         render() {

            const {
                creditCard,
                errors
            } = this.props;

             function Alert(props) {
                 return <MuiAlert elevation={6} variant="filled" {...props} />;
             }

           return (
            <Grid
                container
                justify="center"
                alignItems="center"
            >
                <Grid item xs={12}>

               <Cards
                        cvc={creditCard.SecurityCode}
                        expiry={creditCard.ExpirationDate}
                        focused={this.state.focus}
                        name={creditCard.CardholderName}
                        number={creditCard.CardNumber}
                        placeholders={{name: 'NOME COGNOME' }}
                        locale={{valid: 'Validità mm/aa' }}
                    />     
                </Grid>          
                <Grid item xs={12}>
                <form noValidate autoComplete="off">
                 <Grid
                   container
                   direction="row"
                   justify="center"
                   alignItems="center"
                   spacing={1}
                 >                                       
                    <Grid item xs={12}>
                        <TextField 
                            inputProps={{maxLength: 16}} 
                            placeholder="Numero Carta" 
                            label="Numero Carta" 
                            variant="outlined" 
                            onChange={this.handleInputChange}
                            onBlur={this.handleBlur}
                            onFocus={this.handleInputFocus} 
                            name="CardNumber" 
                            type="tel" 
                            InputProps={{
                                inputComponent: this.NumberFormatCustom,
                            }}
                        />
                        {errors.CardNumber ? <Alert severity="error">{errors.CardNumber}</Alert> : ""}
                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                            placeholder="Nome" 
                            label="Nome" 
                            variant="outlined" 
                            onChange={this.handleInputChange} 
                            onFocus={this.handleInputFocus}
                            onBlur={this.handleBlur}
                            name="CardholderName" 
                            type="text"
                         />
                        {errors.CardholderName ? <Alert severity="error">{errors.CardholderName}</Alert> : ""}
                    </Grid>
                    
                    <Grid item xs={6}>
                        <TextField  
                            value={creditCard.ExpirationDate} 
                            inputProps={{ maxLength: 5 }} 
                            placeholder="Validità" 
                            label="Validità" 
                            variant="outlined" 
                            onChange={this.handleInputChange} 
                            onFocus={this.handleInputFocus} 
                            name="ExpirationDate" 
                            type="tel"
                            onBlur={this.handleBlur}
                        />
                        {errors.ExpirationDate ? <Alert severity="error">{errors.ExpirationDate}</Alert> : ""}
                    </Grid>

                    <Grid item xs={6}>
                        <TextField 
                            inputProps={{ maxLength: 4 }} 
                            placeholder="CVC" 
                            label="CVC/CVV" 
                            variant="outlined" 
                            onChange={this.handleInputChange} 
                            onFocus={this.handleInputFocus} 
                            onBlur={this.handleBlur}
                            name="SecurityCode" 
                            type="tel" 
                            pattern="\d{3,4}" 
                            InputProps={{
                                inputComponent: this.NumberFormatCustom,
                            }}
                        />
                        {errors.SecurityCode ? <Alert severity="error">{errors.SecurityCode}</Alert> : ""}
                    </Grid>

                 </Grid>                 
               </form>
               </Grid>
               <Grid item xs={12}>
                    {errors.errorApi ? <Alert severity="error">{errors.errorApi}</Alert> : ""}

                    <ButtonAction 
                        label="Continua"                         
                    />
                </Grid>
               
            </Grid>
           );
         }
       }


const mapStateToProps = (state) => {
  return {
    creditCard: state.creditCard,
    errors: state.errors
  };
}

export default connect(
  mapStateToProps,
  {
    updateField,
    errorAction
  }
)(Step6);

