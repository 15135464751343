import React, {useState} from 'react'
import { useDispatch } from 'react-redux';
import { updateField } from '../../redux/account/action';
import { MenuItem, FormControl, InputLabel, Select } from '@material-ui/core';
import css from './field.module.sass';


const SelectValue = () => {
    const [value,setValue] = useState("");
    const dispatch = useDispatch();
    const handleChange = (e) => {
        let { value, name } = e.target;
        setValue(value);
        dispatch(updateField(value, name, 'account'))
    }
    return(
        <FormControl variant="outlined" className={css.FormControl}>
            <InputLabel id="gift-value" >Euro</InputLabel>
            <Select
                labelId="gift-value"
                value={value}
                onChange={handleChange}
                label="Euro"
                required
                name="amount"
            >
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={120}>120</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={300}>300</MenuItem>
                <MenuItem value={400}>400</MenuItem>
                <MenuItem value={500}>500</MenuItem>
                <MenuItem value={600}>600</MenuItem>
                <MenuItem value={700}>700</MenuItem>
                <MenuItem value={800}>800</MenuItem>
                <MenuItem value={900}>900</MenuItem>
                <MenuItem value={999}>999</MenuItem>
            </Select>
        </FormControl>
    )
}

export default SelectValue;