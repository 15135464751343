import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateField } from '../../redux/account/action';
import { errorAction } from '../../redux/errors/actions';
import {TextField, Typography, InputAdornment} from '@material-ui/core';

const InputText = ({
    label,
    name,
    multiline,
    rows,
    group,
    type,
    InputProps,
    error
}) => {
    const dispatch = useDispatch();

    const state = useSelector(state => state);

    const handleChange = (e, group) => {
        let regexNumber = /^[0-9]+$/
        let {value, name } = e.currentTarget;
        switch(name){
            case 'phone':
            case 'phoneRecipient':
            case 'zip':
                if(regexNumber.test(value))  dispatch(updateField(value, name, group));
            break;
            default:
                dispatch(updateField(value, name, group));
            break;
        }
    }

    const handleBlur = (group) => {
        dispatch(errorAction(group));
    }    

    return(
        <>
        <Typography variant="body1" align="center" paragraph={true}>
            {label} 
            <span>
                {label === "PEC" && "(se non si è in possesso del Codice SDI)"}
                {label === "Codice SDI" && "(se non si è in possesso della PEC)"}
            </span>
        </Typography>
        <TextField  
            label={label} 
            variant="outlined"
            name={name}
            onChange={(e) => handleChange(e, group)}
            onBlur={() => handleBlur(group)}
            multiline
            rows={rows}
            group={group}
            value={state[group][name]}
            type={type || "text"}
            InputProps={InputProps && {startAdornment: <InputAdornment position="start">{InputProps}</InputAdornment>}}
            error={state[group][name] && error}
        />
        </>
    )
}

export default InputText;